import { Project } from '../models/Project.model';
import { Page } from '../models/Page.model';
const defaultEnvironment = require('../environment/environment.json');
const localEnvironment = require('../environment/environment.local.json');
const siteConfigEnv = Object.freeze(Object.assign({}, defaultEnvironment.siteConfig, localEnvironment.siteConfig));

export interface SiteConfig {
  useCache: boolean;
  logoAsset: string;
  person: {
    firstName: string;
    lastName: string;
  };
  pages: Page[];
  projects: Project[];
}

export function siteConfig(): SiteConfig {
  return siteConfigEnv;
}