import React, { ReactNode } from 'react';
import './Projects.css';
import ProjectImage from './ProjectImage'
import { Project } from './models/Project.model';
import { siteConfig } from './config/config';
import RouterLink from './RouterLink';

interface ProjectsState {
  projects: Project[];
}

class Projects extends React.PureComponent<Record<string, any>, ProjectsState> {
  constructor(props: Record<string, any>) {
    super(props);
    this.state = {
      projects: props.projects || siteConfig().projects,
    };
  }

  renderProject(project: Project): ReactNode {
      const metadata = project.metadata ? <div 
        className="Projects-metadata"
      >
        <span
          className="Projects-metadata-content"
        >
          {project.metadata}
        </span>
      </div> : <></>;

    return (
      <div
        className="Projects-project"
        key={project.name}
        style={{
          width: '50%',
        }}
      >
        <div
          className="Projects-link"
        >
          <RouterLink
            route={project.url}
            title=""
            isExternal={project.newTab || new URL(project.url, window.location.toString()).host !== (window.location.host)}
          >
            <div className="Projects-title">
              {project.name}
            </div>
            <div 
              className="Projects-image"
              style={{
                height: '300px',
              }}
            >
              <ProjectImage images={project.images} />
            </div>
            <div className="Projects-description">
              {project.description}
            </div>
            {metadata}
          </ RouterLink>
        </div>
      </div>
    );
  }

  render(): ReactNode {
    const projects = this.props.projects.map(this.renderProject);
    return (
      <div 
        className="Projects"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {projects}
      </div>
    );
  }
}

export default Projects