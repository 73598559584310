import React, { useEffect, useRef } from 'react';
import './App.css';
import './Stars.css';
import Header from './Header';
import Page, { ComponentKey } from './Page';
import { siteConfig } from './config/config';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import createStars from './Stars';
const siteConfigEnv = siteConfig();

function App(): JSX.Element {
  const skyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    createStars(skyRef.current as HTMLDivElement);
  }, []);

  return (
    <Router>
      <div 
        className="App"
      >
      <div
        className="App-sky"
        ref={skyRef}
      >
      </div>
        <Header />
        <Switch>
          {
            siteConfigEnv.pages
              .filter((page) => page.componentKey)
              .map((page) => (
                <Route
                  exact={page.exact || page.exact === undefined}
                  path={page.route}
                  key={page.route}
                >
                  <Page
                    title={page.title}
                    componentKey={page.componentKey as ComponentKey}
                    componentProps={page.data}
                  />
                </Route>
              ))
          }
        </Switch>
      </div>
    </Router>
  );
}

export default App;