import React, { useEffect } from 'react';
import Projects from './Projects';
import About from './About';
import Headlines from './Headlines';
import Articles from './Articles';
import Contact from './Contact';
import { pageTitle } from './Content';

export enum ComponentKey {
  Projects = 'projects',
  About = 'about',
  Headlines = 'headlines',
  Articles = 'articles',
  Contact = 'contact',
}

type ComponentFromName = Record<ComponentKey, React.ComponentClass | React.FunctionComponent<any>>;
const componentFromName: ComponentFromName = {
  projects: Projects,
  about: About,
  headlines: Headlines,
  articles: Articles,
  contact: Contact,
}

function Page(props: {title: string; componentKey: ComponentKey; componentProps: any}) {
  useEffect(() => {
    document.title = pageTitle(props.title);
  }, [props.title]);
  const component = componentFromName[props.componentKey];
  return React.createElement(component, props.componentProps);
}

export default Page;