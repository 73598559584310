import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Projects from './Projects';
import Resource, { useResources } from './Resource';
import { Project } from './models/Project.model';
import Article from './Article';
import RouterLink from './RouterLink'
import './Articles.css';

export interface ArticlesItem {
  id: string;
  published_timestamp: string;
  url: string;
  title: string;
  description: string;
  social_image: string;
  user: {
    name: string;
    username: string;
  }
};

export interface ArticlesProps {
  title: string;
  authors: string[];
};

function Articles(props: ArticlesProps): JSX.Element {
  const match = useRouteMatch();
  useEffect(() => {
    document.title = props.title;
  }, [match, props.title]);
  const authorResources = props.authors.map(url => new Resource(url));
  const resources = useResources(authorResources);
  const articles: Project[] = resources.resources.map(resource => (resource.resource$.value || []) as ArticlesItem[]).flat().sort((a: ArticlesItem, b: ArticlesItem) => {
    return a.published_timestamp > b.published_timestamp ? -1 : b.published_timestamp < a.published_timestamp ? 1 : 0;
  }).map((item: ArticlesItem) => ({
    name: item.title,
    description: item.description,
    metadata: `${item.user.username} · ${item.user.name} · ${item.published_timestamp}`,
    url: `${match.path}/${item.user.username}/${item.id}`,
    newTab: false,
    images: [
      {
        name: 'Cover Image',
        url: item.social_image,
      }
    ],
  }));
  return (
    <div
      className="Articles"
    >
      <Switch>
        <Route
          path={`${match.path}/:author/:articleID`}
        >
          <h2
            className="Articles-heading"
          >
            <RouterLink
              route={match.path}
              title=""
              isExternal={false}
            >
              <span><span className="Articles-bullet">&#8227;</span> {props.title}</span>
            </RouterLink>
          </h2>
          <Article />
        </Route>
        <Route
          path={match.path}
        >
          <h2
            className="Articles-heading"
          >
            {props.title}
          </h2>
          <Projects
            projects={articles}
          />
        </Route>
      </Switch>
    </div>
  );
}

export default Articles;