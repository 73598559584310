import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import './Headline.css';
import RouterLink from './RouterLink';

export interface HeadlineImage {
  url: string;
  altText: string;
  maxWidth?: number;
  entryAnimation?: string;
  exitAnimation?: string;
  top?: string;
  side?: string;
  classes?: string[];
}

export interface HeadlineLink {
  url: string;
  text?: string;
  external?: boolean;
  newTab?: boolean;
}

export interface HeadlineProps {
  name: string;
  description: string;
  html: string;
  left: boolean;
  minVerticalSpace: string;
  minHeight: string;
  topMargin: string;
  bottomMargin: string;
  topPadding: string;
  sidePadding: number;
  leftPadding: number;
  rightPadding: number;
  images: HeadlineImage[];
  links: HeadlineLink[];
  canFocus: boolean;
  dark: boolean;
}

function Headline(props: Partial<HeadlineProps>) {
  const options = Object.assign({
    name: '',
    description: '',
    html: '',
    left: true,
    minVerticalSpace: '0',
    minHeight: '0',
    bottomMargin: '0',
    topPadding: '160px',
    sidePadding: 20,
    leftPadding: 20,
    rightPadding: 0,
    images: [],
    links: [],
    canFocus: true,
    dark: false,
  }, props) as HeadlineProps;

  const contentRef = useRef<HTMLDivElement>(null);
  const [unloaded, setUnloaded] = useState<number>(options.images.length);
  const [focused, setFocused] = useState<Boolean>(false);
  const [imageIndex, setImageIndex] = useState<number>(0)

  useEffect(() => {
    if (!contentRef.current) { return; }
    contentRef.current.innerHTML = options.html;
    if (options.description) {
      const descriptionElement = document.createElement('span');
      descriptionElement.className = 'Headline-description';
      descriptionElement.append(options.description);
      contentRef.current.prepend(descriptionElement);
    }
    if (options.name) {
      const nameElement = document.createElement('strong');
      nameElement.className = 'Headline-name';
      nameElement.append(options.name + ' ');
      contentRef.current.prepend(nameElement);
    }
  }, [contentRef, options.html, options.description, options.name]);
  const classNames = [
    'Headline',
    options.left ? 'Headline-left' : 'Headline-right',
  ];
  if (options.dark) { classNames.push('Headline-dark'); }
  if (!unloaded) { classNames.push('Headline-loaded'); }
  if (focused) {
    classNames.push('Headline-focused');
  }
  const focus = useCallback((isFocused: Boolean) => {
    if (!options.canFocus) { return; }
    setFocused(isFocused);
    if (isFocused && options.images.length) {
      setImageIndex((imageIndex + 1) % options.images.length);
    }
  }, [imageIndex, options.images, options.canFocus]);
  const images = options.images.map((image: HeadlineImage, index: number) => {
    const imageStyle: CSSProperties = {};
    if (image.maxWidth !== undefined) { imageStyle.maxWidth = image.maxWidth + '%'; }
    if (image.top !== undefined) { imageStyle.top = image.top; }
    if (image.side !== undefined) { imageStyle[options.left ? 'left': 'right'] = image.side; }
    const imageClassNames = ['Headline-image'];
    if (image.classes) { imageClassNames.push(...image.classes); }
    if (index === imageIndex) {
      imageClassNames.push('Headline-image-active');
    }
    return (
      <img
        key={image.altText}
        className={imageClassNames.join(' ')}
        src={image.url}
        alt={image.altText}
        onLoad={() => setUnloaded(unloaded - 1)}
        style={imageStyle}
      />
    );
  });
  const links = options.links.map(link => {
    let linkElement: JSX.Element;
    if (!link.external && link.external !== undefined) {
      linkElement = <RouterLink
          route={link.url}
          title={link.text === undefined ? link.url : link.text}
          className="Headline-link"
          isExternal={false}
        />;
    } else {
      const linkAttributes: Record<string, any> = {};
      if (link.newTab) { linkAttributes.target = '_blank'; }
      linkElement = <a
        className="Headline-link"
        href={link.url}
        key={link.url}
        {...linkAttributes}
        >{link.text === undefined ? link.url : link.text}</a>;  
    }
    return linkElement;
  });
  return (
    <div 
      className={classNames.join(' ')}
      onClick={() => focus(focused && !imageIndex ? false : true)}
      onMouseEnter={() => focus(true)}
      onMouseLeave={() => focus(false)}
      style={{
        paddingTop: options.topPadding,
        marginBottom: options.bottomMargin,
        minHeight: options.minVerticalSpace,
      }}
    >
      {images}
      <div
        className="Headline-container"
        style={{
          width: 100 - options.sidePadding + '%',
          minHeight: options.minHeight,
        }}
      >
        <div
          className="Headline-shield"
          style={{
            width: 100 - options.leftPadding - options.rightPadding + '%',
            marginLeft: options.leftPadding + '%',
            marginRight: options.rightPadding + '%'
          }}
        ></div>
        <div
          className="Headline-texture"
          style={{
            width: 100 - options.leftPadding - options.rightPadding + '%',
            marginLeft: options.leftPadding + '%',
            marginRight: options.rightPadding + '%'
          }}
        ></div>
        <div
          className="Headline-content-container"
          style={{
            width: 100 - options.leftPadding - options.rightPadding + '%',
            marginLeft: options.leftPadding + '%',
            marginRight: options.rightPadding + '%'
          }}
        >
          <div
            className="Headline-content"
          >
            <div
              ref={contentRef}
            ></div>
            <div
              className="Headline-links"
            >
             {links}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Headline;