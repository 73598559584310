import React, { useEffect, useRef, useState } from 'react';
import { iProjectImage } from './models/Project.model';
import './ProjectImage.css';


interface ProjectImageProps {
  images: iProjectImage[];
}

function ProjectImage(props: ProjectImageProps): JSX.Element {
  const [imageIndex, setImageIndex] = useState(0);
  const [unloaded, setUnloaded] = useState<number>(props.images.length);
  const slideTimeout = useRef<number|null>(null);
  
  useEffect(() => {
    if (slideTimeout.current !== null) { clearTimeout(slideTimeout.current) }
    const timeout = window.setTimeout(() => {
      setImageIndex((imageIndex + 1) % props.images.length);
      slideTimeout.current = null;
    }, 3000);
    slideTimeout.current = timeout;
    return () => { clearTimeout(timeout); }
  }, [imageIndex, props.images]);

  if (!props.images.length) { return (<></>); }

  const image = props.images[imageIndex];
  const classNames = ['ProjectImage'];
  if (!unloaded) { classNames.push('ProjectImage-loaded'); }
  return (
    <img 
      className={classNames.join(' ')}
      src={image.url || `/assets/project-images/${image.name}.png`} 
      alt={image.altText || image.name}
      onLoad={() => setUnloaded(unloaded - 1)}
      style={{
        height: '100%',
      }}
    />
  );
}

export default ProjectImage;