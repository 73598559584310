import React from 'react';
import Headline, { HeadlineProps } from './Headline';
import './Headlines.css';

interface HeadlinesItem extends HeadlineProps {
  name: string;
  description: string;
  url: string;
}

interface HeadlinesProps {
  main: Partial<HeadlinesItem>;
  headlines: Partial<HeadlinesItem>[];
}

function Headlines(props: Partial<HeadlinesProps>): JSX.Element {
  let main = <></>;
  if (props.main) {
    const mainProps = Object.assign({
      bottomMargin: '90px',
    }, props.main);
    main = React.createElement(Headline, mainProps);
  }
  const headlines = props.headlines ? props.headlines.map(headline => {
    const headlineProps = Object.assign({
      bottomMargin: '77px',
      topPadding: '0',
      left: false,
      key: headline.name,
    }, headline);
    const headlineElement = React.createElement(Headline, headlineProps);
    return headlineElement;
  }) : [];
  return (
    <div className="Headlines">
      {main}
      {headlines}
    </div>
  );
}

export default Headlines;