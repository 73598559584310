import React from 'react';
import './Title.css';
import { siteConfig } from './config/config';

function Title() {
  const { person } = siteConfig();
  return (
    <div className="Title">
      <div className="Title-name">
        {person.firstName}&nbsp;{person.lastName}
      </div>
    </div>
  );
}

export default Title;