function createStars(element: HTMLElement) {
  console.log('stars element', element);
  const sky = renderSky();
  element.append(sky);
  let sets = Math.round(3 + Math.random() * 13);
  renderStars(element, sets);
}

function renderStars(element: HTMLElement, sets: number) {
  if (sets <= 0) { return; }
  setTimeout(() => {
    const stars = Math.round(3 + Math.random() * 5);
    for (let i = 0; i < stars; i++) {
      const star = renderStar();
      element.append(star);
    }
    renderStars(element, sets - 1);  
  }, Math.random() * 5000);
}

function renderSky(): HTMLElement {
 const sky = document.createElement('div');
 sky.className = 'Stars-sky';
 return sky;
}

function renderStar(): HTMLElement {
  const star =  document.createElement('div');
  star.className = 'Stars-star';
  const randomProperties = Object.assign(
    {
      'background-color': randomColor(),
    },
    randomPostion(),
  );
  const size = randomSize();
  for (const [key, value] of Object.entries(size)) {
    randomProperties[key] = value + 'px';
  }
  const shadow = Math.round(Math.random() * (size.width + size.height / 2))
  randomProperties['box-shadow'] = '0 0 ' + shadow + 'px ' + randomProperties['background-color'];
  for (const [key, value] of Object.entries(randomProperties)) {
    star.style.setProperty(key, value);
  }
  Object.assign(star.style, randomPostion());
  return star;
}

function randomPostion(): Record<string, any> {
  const top = Math.random() * 100;
  const left = Math.random() * 100;
  const position: Record<string, any> = {};
  if (top > 50) {
    position.bottom = 100 - top + '%';
  } else {
    position.top = top + '%';
  }
  if (left > 50) {
    position.right = 100 - left + '%';
  } else {
    position.left = left + '%';
  }
  return position;
}

function randomColor(): String {
  const red = 0.5 + Math.random() / 2;
  const green = 0.5 + Math.random() / 2;
  const blue = 0.75 + Math.random() / 4;
  return 'rgb(' + Math.round(red * 255) + ',' + Math.round(green * 255) + ',' + Math.round(blue * 255) + ')';
}

function randomSize(): Record<string, any> {
  const size = Math.round(1 + Math.random() * 2);
  return {
    width: size,
    height: size,
    // 'border-radius': Math.round(Math.random() * size),
  }
}

export default createStars;