import React from 'react';
import { Link } from 'react-router-dom';

export function ExternalLink(props: {route: string, title: string, className?: string; children?: JSX.Element[]|JSX.Element}) {
  return (
    <a className={props.className} href={props.route} target="_blank" rel="noreferrer">{props.title}{props.children}</a>
  );
}

function RouterLink(props: {route: string; title: string; isExternal: boolean; className?: string; children?: JSX.Element[]|JSX.Element;}) {
  return (
    <div
      key={props.route}
    >
      {
        props.isExternal 
      ? <ExternalLink className={props.className} route={props.route} title={props.title}>{props.children}</ExternalLink>
      : <Link className={props.className} to={props.route}>{props.title}{props.children}</Link>
      }
    </div>
  );
}

export default RouterLink;