import React, { useCallback, useEffect, useState } from 'react';
import './Header.css';
import Title from './Title';
import { siteConfig } from './config/config';
import RouterLink from './RouterLink';

function Header() {
  const [opaque, setOpaque] = useState<boolean>(!!window.pageYOffset);

  const handleScroll = useCallback(() => {
    if (window.pageYOffset && !opaque) {
      setOpaque(true);
    } else if (!window.pageYOffset && opaque) {
      setOpaque(false);
    }
  }, [opaque]);
  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const pages = siteConfig().pages;
  let headerClass = 'Header-menu';
  if (opaque) {
    headerClass += ' Header-opaque';
  }
  return (
    <div className="Header">
      <header className={headerClass}>
        <RouterLink
          route="/"
          title=""
          isExternal={false}
        >
          <div className="Header-site-title">
            <img src={siteConfig().logoAsset} className="App-logo" alt="logo" />
            <Title />
          </div>
        </RouterLink>
        <div className="Header-links">
        {
          pages.map(page => RouterLink({
            route: page.route, 
            title: page.title, 
            isExternal: !page.componentKey
          }))
        }
        </div>
      </header>
    </div>
  );
}

export default Header;