import { useEffect, useRef, useState } from 'react';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { siteConfig } from './config/config';
const cache = require('./cache.json');
const useCache = siteConfig().useCache;

type ResourceValue = Record<string, any>|any[];

export function useResource(url?: string, localValue?: ResourceValue) {
  const resource = useRef<Resource>(new Resource(url, localValue));
  const [resourceState, setResourceState] = useState<any>(null);
  useEffect(() => {
    const subscription = resource.current.resource$.subscribe((resource) => {
      setResourceState(resource);
    });
    resource.current.pull();
    return () => subscription.unsubscribe();
  }, []);

  return {
    resource: resourceState,
    pull: () => resource.current.pull(),
  };
}

export function useResources(resources: Resource[]) {
  const resourcesRef = useRef<Resource[]>(resources);
  const [resourcesState, setResourcesState] = useState<Resource[]>(resourcesRef.current);
  useEffect(() => {
    const subscriptions = resourcesRef.current.map(resource => resource.resource$.subscribe(() => {
      setResourcesState([...resourcesRef.current]);
    }));
    resourcesRef.current.forEach(resource => resource.pull());
    return () => subscriptions.forEach(subscription => subscription.unsubscribe());
  }, []);

  return {
    resources: resourcesState,
    pull: () => resourcesState.forEach(resource => resource.pull()),
  };
}

class Resource {
  url: string|null;
  localValue: ResourceValue|null;
  resource$ = new BehaviorSubject<ResourceValue|null>(null);
  private urlLoader = new Subject<string|null>();
  private subscription: Subscription|null = null;

  constructor(url?: string, localValue?: ResourceValue) {
    this.url = url || null;
    this.localValue = localValue || null;
    this.resource$.next(this.localValue);
    this.subscription = this.urlLoader.pipe(switchMap(url => this.fetch(url))).subscribe(resourceValue => this.merge(resourceValue));
  }

  async fetch(url: string|null = null): Promise<ResourceValue|null> {
    if (url === null) { url = this.url; }
    if (!url) { return null; }
    const request = new Request(url);
    try {
      const response = await fetch(request);
      if (!response.ok) {
        const message = `Resource response status code ${response.status} for URL ${request.url}`;
        if (useCache && cache[url] !== undefined) {
          const cachedResponse = JSON.parse(cache[url]) as ResourceValue;
          console.assert(response.ok, message + '; using cached value');
          return cachedResponse;
        } else {
          throw new Error(message);
        }
      }
      const responseBody = await response.json();
      return responseBody as ResourceValue;
    } catch (e) {
      console.assert(!e, 'Resource fetch error', e);
      return null;
    }
  }

  async pull(url: string|null = null) {
    this.urlLoader.next(url);
  }

  merge(resource: ResourceValue|null) {
    if (!resource) { return; }
    const currentValue = this.resource$.value;
    let nextValue = resource;
    if (Array.isArray(currentValue)) {
      if (Array.isArray(resource)) {
        nextValue = currentValue.concat(resource);
      }
    } else if (currentValue && !Array.isArray(resource)) {
      nextValue = Object.assign({}, currentValue, resource);
    }
    this.resource$.next(nextValue);
  }

  set(resource: ResourceValue|null) {
    this.urlLoader.next('');
    this.resource$.next(resource);
  }

  destroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }
}

export default Resource;
