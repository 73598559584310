import React from 'react';
import './Contact.css';

export interface ContactLink {
  url: string;
  text?: string;
  newTab?: boolean;
}

export interface ContactProps {
  title: string;
  links: ContactLink[];
}

function Contact(props: ContactProps): JSX.Element {
  const links = props.links.map(link => {
    const linkElement = (
      <div
        className="Contact-link"
      >
        <a
          key={link.url}
          href={link.url}
          target={link.newTab || link.newTab === undefined ? '_blank' : undefined}
    >
      {link.text || link.url}
    </a>
      </div>
    );
    return linkElement;
  });
  return (
    <div
      className="Contact"
    >
      <h2
        className="Contact-heading"
      >
        {props.title}
      </h2>
      <div
        className="Contact-links"
      >
        {links}
      </div>
    </div>
  );
}

export default Contact;