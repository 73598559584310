import React from 'react';
import './About.css';
import Headline, { HeadlineLink } from './Headline';

export interface AboutContent {
  title: string;
  description: string;
  html: string;
  links: HeadlineLink[];
}

export interface AboutProps {
  main: Partial<AboutContent>;
  backEnd: Partial<AboutContent>;
  frontEnd: Partial<AboutContent>;
  apps: Partial<AboutContent>;
}

function About(props: AboutProps): JSX.Element {
  return (
    <div className="About">
      <Headline
      bottomMargin="90px"
      {...props.main}
        images={[
          {
            url: 'assets/images/elevator.png',
            altText: 'Human in glass elevator Photo by Amit Thakral from Pexels',
          }
        ]}
      />
      <Headline
        left={false}
        sidePadding={40}
        topPadding="20px"
        leftPadding={23}
        rightPadding={5}
        bottomMargin="37px"
        minVerticalSpace="10vw"
        {...props.backEnd}
        images={[
          {
            url: 'assets/images/python.png',
            altText: 'Python Photo by Jan Kopřiva from Pexels',
            maxWidth: 17,
            side: '2vw',
            classes: ['Headline-drain'],
          },
          {
            url: 'assets/images/python-logo.png',
            altText: 'Python Logo',
            maxWidth: 17,
            side: '2vw',
            classes: ['Headline-drain'],
          }
        ]}
      />
      <Headline
        left={false}
        sidePadding={40}
        leftPadding={23}
        rightPadding={0}
        topPadding="60px"
        bottomMargin="77px"
        minVerticalSpace="7vw"
        {...props.apps}
        images={[
          {
            url: 'assets/images/falcon.png',
            altText: 'Falcon Photo by Brett Sayles from Pexels',
            maxWidth: 12,
            classes: ['Headline-flip'],
          },
          {
            url: 'assets/images/swift-logo.png',
            altText: 'Swift',
            maxWidth: 12,
            classes: ['Headline-flip'],
          }
        ]}
      />
      <Headline
        left={false}
        sidePadding={40}
        leftPadding={23}
        rightPadding={2}
        topPadding="10px"
        minVerticalSpace="10vw"
        {...props.frontEnd}
        images={[
          {
            url: 'assets/images/plane.png',
            altText: 'Airplane Photo by Tomas Anunziata from Pexels',
            maxWidth: 15,
            side: '1vw',
            classes: ['Headline-shrink'],
          },
          {
            url: 'assets/images/angular-logo.png',
            altText: 'Angular Logo',
            maxWidth: 15,
            side: '1vw',
            classes: ['Headline-shrink'],
          }
        ]}
      />
    </div>
  )
}

export default About;