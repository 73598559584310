import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Article.css';
import { useResource } from './Resource';
import Headline from './Headline';
import './Article.css';
import { pageTitle } from './Content';

export interface ArticleContent {
  url: string;
  title: string;
  body_html: string;
  cover_image: string|null;
  published_timestamp: string;
  user: {
    name: string;
  };
}

function Article(): JSX.Element {
  const { articleID } = useParams() as { articleID: string; };
  const articleURL = `https://dev.to/api/articles/${articleID}`;
  const resource = useResource(articleURL);
  const article = resource.resource as ArticleContent;
  useEffect(() => {
    if (!article) { return; }
    document.title = pageTitle(article.title, article.user.name);
  }, [article]);
  if (!article) { return <></>; }
  return (
    <div
      className="Article"
    >
      <div
        className='Article-header'
      >
        <Headline
          name={article.title}
          description={`\n\nby ${article.user.name} · ${article.published_timestamp}`}
          canFocus={false}
          topPadding="22vh"
          bottomMargin="8px"
          leftPadding={45}
          images={article.cover_image ? [
            {
              url: article.cover_image,
              altText: 'Cover Image',
              maxWidth: 60,
            }
          ] : undefined}
        />
      </div>
      <div
        className="Article-body"
      >
        <Headline
          html={article.body_html}
          topPadding="0"
          leftPadding={0}
          sidePadding={33}
          bottomMargin="8px"
          canFocus={false}
          dark={true}
        />
      </div>
      <div
        className="Article-footer"
      >
        <Headline
          canFocus={false}
          topPadding="0"
          leftPadding={45}
          description="Comment or follow at"
          links={[
            {
              url: article.url,
              newTab: true,
            }
          ]}
        />
      </div>
    </div>
  );
}

export default Article;